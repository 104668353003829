<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="姓名">
          <el-input v-model="searchForm.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchForm.mobile" placeholder="手机号"></el-input>
        </el-form-item>

        <el-form-item label="注册时间段">
          <div class="block">
            <!-- <span class="demonstration">注册时间段</span> -->
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="业主状态">
          <el-select v-model="searchForm.user_status" placeholder="业主状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in ownerStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="searchForm.project_id" placeholder="选择项目">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete()"
            >
              删除
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            />
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="mobile"
              label="业主手机号"
              width="180"
              align="center"
            />
            <el-table-column
              prop="name"
              label="业主名称"
              width="180"
              align="center"
            />

            <el-table-column
              prop="project_name"
              label="所属项目"
              width="180"
              align="center"
            />

            <el-table-column
              prop="building_or_area"
              label="栋or区域"
              align="center"
            />
            <el-table-column prop="unit" label="单元" align="center" />
            <el-table-column prop="floor" label="楼层" align="center" />
            <el-table-column
              prop="house_number"
              label="门牌号"
              align="center"
            />
            <el-table-column
              prop="user_status_name"
              label="业主状态"
              align="center"
            />
            <el-table-column
              prop="status_name"
              label="物业数据状态"
              align="center"
            />

            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.create_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">
                  <el-button
                    class="color-error"
                    type="text"
                    icon="el-icon-success"
                    v-if="smallRoleObj.examine"
                    @click="
                      dialogExamineFormVisible = true;
                      examineForm.id = scope.row.id;
                      examineForm.user_id = scope.row.user_id;
                    "
                  >
                    审核
                  </el-button>
                </div>
                <div v-else>
                  <el-button
                    class="color-primary"
                    type="text"
                    icon="el-icon-edit"
                    v-if="smallRoleObj.update"
                    @click="
                      $router.push({
                        name: 'OWNER_EDIT',
                        params: { id: scope.row.id },
                      })
                    "
                  >
                    编辑
                  </el-button>
                  <el-button
                    class="color-danger"
                    type="text"
                    icon="el-icon-phone"
                    v-if="smallRoleObj.mobile_update"
                    @click="
                      dialogFormVisible = true;
                      mobileUpdateId = scope.row.user_id;
                    "
                  >
                    手机号变更
                  </el-button>
                  <el-button
                    class="color-error"
                    type="text"
                    icon="el-icon-s-help"
                    v-if="smallRoleObj.status_update"
                    @click="
                      dialogStatusFormVisible = true;
                      statusUpdateId = scope.row.user_id;
                      statusUpdateForm.status = scope.row.user_status;
                    "
                  >
                    状态变更
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>

      <!-- 手机号变更弹窗start -->
      <el-dialog title="手机号变更" :visible.sync="dialogFormVisible">
        <el-form :model="mobileUpdateForm">
          <el-form-item label="新手机号" :label-width="formLabelWidth">
            <el-input
              v-model="mobileUpdateForm.mobile"
              autocomplete="off"
              placeholder="请输入新手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员验证" :label-width="formLabelWidth">
            <el-input
              v-model="mobileUpdateForm.password"
              autocomplete="off"
              type="password"
              placeholder="请输入当前账号的密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle()">取 消</el-button>
          <el-button type="primary" @click="handleMobileUpdate()">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 手机号变更弹窗end -->

      <!-- 状态变更start -->
      <el-dialog title="状态变更" :visible.sync="dialogStatusFormVisible">
        <el-form :model="statusUpdateForm" class="page-form" label-width="84px">
          <el-form-item label="业主状态：" :label-width="formLabelWidth">
            <el-select
              v-model="statusUpdateForm.status"
              placeholder="业主状态"
              @change="statusChange"
            >
              <el-option
                v-for="item in ownerStatus"
                :key="item.value"
                :label="item.name"
                :value="Number(item.value)"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="管理员验证：" :label-width="formLabelWidth">
            <el-input
              v-model="statusUpdateForm.password"
              autocomplete="off"
              type="password"
              placeholder="请输入当前账号的密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle()">取 消</el-button>
          <el-button type="primary" @click="handleStatusUpdate()">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 状态变更end -->

      <!-- 审核start -->
      <el-dialog title="审核" :visible.sync="dialogExamineFormVisible">
        <el-form :model="examineForm" class="page-form" label-width="84px">
          <el-form-item label="审核状态:" :label-width="formLabelWidth">
            <el-radio-group v-model="examineForm.status">
              <el-radio :label="3">审核通过</el-radio>
              <el-radio :label="2">审核失败</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="审核意见：" :label-width="formLabelWidth">
            <el-input
              v-model="examineForm.remarks"
              autocomplete="off"
              type="textarea"
              placeholder="输入审核意见（失败必填）"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle()">取 消</el-button>
          <el-button type="primary" @click="handleExamine()"> 确 定 </el-button>
        </div>
      </el-dialog>
      <!-- 审核start -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  getOwnerList,
  ownerDelete,
  ownerUpdate,
  ownerMobileUpdate,
  ownerStatusUpdate,
  ownerExamine,
} from "@api/user"; // 引用./src/api/user.js中的'getOwnerList'方法
import { getCompanyListTree, getCompanyList } from "@api/company";
import { getFrontRoleList } from "@api/frontRole";
import { getProjectList } from "@api/project";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
import { formatTime } from "@utils/index";
export default {
  name: "ownerList",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {
        branch_id: "",
      }, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据
      ownerStatus: [
        {
          value: "1",
          name: "待审",
        },
        {
          value: "2",
          name: "正常",
        },
        {
          value: "3",
          name: "驳回",
        },
        {
          value: "4",
          name: "禁用",
        },
        {
          value: "5",
          name: "已搬离",
        },
      ],
      companyList: [], //单位列表
      frontRoleList: [], //角色列表
      projectList: [], //项目列表
      smallRoleObj: {},

      // 手机号变更 弹窗
      mobileUpdateId: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      mobileUpdateForm: {
        //手机号变更弹窗表单
        id: "",
        mobile: "",
        password: "",
      },
      formLabelWidth: "120px",
      // 状态变更弹窗
      statusUpdateId: 0,
      dialogTableVisible: false,
      dialogStatusFormVisible: false,
      statusUpdateForm: {
        //状态变更弹窗表单
        id: "",
        status: "",
        password: "",
      },

      //审核弹窗
      dialogTableVisible: false,
      dialogExamineFormVisible: false,
      examineForm: {
        //审核弹窗表单
        id: "",
        user_id: "",
        status: 3,
        remarks: "",
      },

      pickerOptions: {
        //时间段组件配置
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [], //时间段默认时间

      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  async created() {
    // 获取初始化数据
    let res = await getCompanyList();
    this.companyList = res.data;
    let res2 = await getFrontRoleList();
    this.frontRoleList = res2.data;
    let res3 = await getProjectList();
    this.projectList = res3.data;
    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      this.tableLoading = true;
      tempParams = {
        ...this.searchForm,
        ...tempParams,
        min_time: (this.value1 && this.value1[0]) || "",
        max_time: (this.value1 && this.value1[1]) || "",
      };
      /**
       * 请求接口数据
       */
      let res = await getOwnerList(tempParams);
      this.tableData = res.data.list;

      this.pagination.total = res.data.count;
      this.$forceUpdate();
      this.tableLoading = false;
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 取消表单 重置参数
     */
    handleCancle() {
      this.dialogFormVisible = false;
      this.mobileUpdateId = 0;
      this.mobileUpdateForm = {};

      this.dialogStatusFormVisible = false;
      this.statusUpdateId = 0;
      this.statusUpdateForm = {};

      this.dialogExamineFormVisible = false;
      this.examineForm = {
        status: 3,
      };
    },
    /** 删除用户 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(async () => {
            /**
             * 请求接口删除数据
             */
            let res = await ownerDelete(id);
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            /** 判断当前页数据是否仅剩1条 */
            if (this.tableData.length == 1) {
              this.pagination = {
                ...this.pagination,
                page:
                  (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
              };
            }
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            let tempParams = {
              ...this.searchForm,
              page: this.pagination.page,
              rows: this.pagination.size,
            };
            this.getData(tempParams);
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
    /**
     * 手机号变更
     */
    async handleMobileUpdate() {
      let params = {
        user_id: this.mobileUpdateId,
        mobile: this.mobileUpdateForm.mobile,
        password: this.mobileUpdateForm.password,
      };
      let res = await ownerMobileUpdate(params, "post");
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.handleCancle();
      this.getData();
    },
    /** 状态变更 - status值切换 */
    statusChange(e) {
      this.statusUpdateForm = {
        ...this.statusUpdateForm,
        status: e,
      };
    },
    /**
     * 状态变更
     */
    async handleStatusUpdate() {
      let params = {
        user_id: this.statusUpdateId,
        status: this.statusUpdateForm.status,
        password: this.statusUpdateForm.password,
      };
      let res = await ownerStatusUpdate(params, "post");
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.handleCancle();
      this.getData();
    },
    /**
     * 审核
     */
    async handleExamine() {
      let params = {
        id: this.examineForm.id,
        user_id: this.examineForm.user_id,
        status: this.examineForm.status,
        remarks: this.examineForm.remarks,
      };
      let res = await ownerExamine(params);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.handleCancle();
      this.getData();
    },
  },
};
</script>